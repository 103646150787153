import { AuthUserGuard } from './guards/auth-gaurd.guard';
import { CurrentPageGuard } from './guards/current-page.guard';
import { redirectGuard } from './guards/redirect.guard';

export const allRoutes = [
  {
    path: 'get-started',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/home-page/get-started/get-started.component'
      ).then((c) => c.GetStartedComponent),
  },
  {
    path: 'select-location',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/select-location/select-location.component'
      ).then((c) => c.SelectLocationComponent),
  },
  {
    path: 'create-account',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/create-account/create-account.component'
      ).then((c) => c.CreateAccountComponent),
  },
  {
    path: 'otp',
    // canActivate: [CurrentPageGuard],
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/phone-number-otp-confirm/phone-number-otp-confirm.component'
      ).then((c) => c.PhoneNumberOtpConfirmComponent),
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/signup-page/signup-page.component'
      ).then((c) => c.SignupPageComponent),
  },
  {
    // path: 'sign-in',
    path: '',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/sign-in/sign-in.component'
      ).then((c) => c.SignInComponent),
  },
  {
    path: 'edit-location/:id',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/edit-location/edit-location.component'
      ).then((c) => c.EditLocationComponent),
  },
  {
    path: 'ForgotPassword',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/forgot-password/forgot-password.component'
      ).then((c) => c.ForgotPasswordComponent),
  },
  {
    path: 'password-confirmation',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/forgot-password-otp-confirm/forgot-password-otp-confirm.component'
      ).then((c) => c.ForgotPasswordOtpConfirmComponent),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import(
        '../app/components/authentication-flow/reset-password/reset-password.component'
      ).then((c) => c.ResetPasswordComponent),
  },
  {
    path: 'g-maps',
    loadComponent: () =>
      import(
        '../app/components/home-page/google-maps/google-maps.component'
      ).then((c) => c.GoogleMapsComponent),
  },
  {
    path: 'home',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/home-page/home-page/home-page.component'
      ).then((c) => c.HomePageComponent),
  },
  {
    path: 'products',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/home-page/products-card/products-card.component'
      ).then((c) => c.ProductsCardComponent),
  },

  {
    path: 'product-details/:id',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/cart-details/product-details/product-details.component'
      ).then((c) => c.ProductDetailsComponent),
  },

  {
    path: 'cart',
    loadComponent: () =>
      import(
        './components/cart-details/cart-container/cart-container.component'
      ).then((c) => c.CartContainerComponent),
  },
  {
    path: 'change-location',
    loadComponent: () =>
      import(
        './components/cart-details/edit-address/edit-address.component'
      ).then((c) => c.EditAddressComponent),
  },

  {
    path: 'address',
    loadComponent: () =>
      import(
        './components/cart-details/address/address.component'
      ).then((c) => c.AddressComponent),
  },

{
    path: 'info',
    loadComponent: () =>
      import(
        '../app/components/personal-profile/personal-account/personal-account.component'
      ).then((c) => c.PersonalAccountComponent),
  },
  {
    path: 'my-account',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/personal-profile/my-account/my-account.component'
      ).then((c) => c.MyAccountComponent),
  },
  {
    path: 'change-password',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/personal-profile/change-password/change-password.component'
      ).then((c) => c.ChangePasswordComponent),
  },
  {
    path: 'change-language',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/personal-profile/change-language/change-language.component'
      ).then((c) => c.ChangeLanguageComponent),
  },
  {
    path: 'set-location',
    canActivate:[AuthUserGuard],
    loadComponent: () =>
      import(
        '../app/components/personal-profile/set-location/set-location.component'
      ).then((c) => c.SetLocationComponent),
  },
  
  {
    path: 'add-address',
    loadComponent: () =>
      import(
        '../app/components/personal-profile/add-address/add-address.component'
      ).then((c) => c.AddAddressComponent),
  },
  {
    path: 'products/:id',
    loadComponent: () =>
      import(
        '../app/components/home-page/items-section/items-section.component'
      ).then((c) => c.ItemsSectionComponent),
  },
  {
    path: 'order-confirmation',
    loadComponent: () =>
      import(
        './components/cart-details/confirmation-page/confirmation-page.component'
      ).then((c) => c.ConfirmationPageComponent),
  },
  {
    path:'**',
    redirectTo:'home'
  }
  
];
