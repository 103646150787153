import {
    HttpEvent,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptorFn,
    HttpHandlerFn,
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  import { catchError } from 'rxjs/operators';
  import { inject } from '@angular/core';
import { AuthenticationServicesService } from '../services/auth-services/authentication-services.service';
import { ToasterService } from '../components/shared/toaster/toaster.service';
export const HttpErrorInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
  ): Observable<HttpEvent<any>> => {
    const _auth = inject(AuthenticationServicesService);
    const toasterService = inject(ToasterService);
    return next(req).pipe(
                catchError((error: HttpErrorResponse) => {
                  let errorMsg = error?.error?.Error?.length > 0 ? error?.error?.Error[0] : error?.message;
                  if (error.status == 401) {
                    _auth.signOut();
                    return throwError(errorMsg);
                  } else {
                    toasterService.openSnackBar(errorMsg);
                    return throwError(error);
                  }
                })
            )
  };