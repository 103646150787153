import { Component } from '@angular/core';
import { RouterOutlet,Router, RouterLink  } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationServicesService } from './services/auth-services/authentication-services.service';
import { ToasterComponent } from './components/shared/toaster/toaster.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  loading: boolean = false;
  title = 'online-ordering';
  language = localStorage.getItem('language');
  constructor(private translationService: TranslateService,
    private authenticationService: AuthenticationServicesService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.getRestaurantBySubdomain();
    if (!this.language)
      localStorage.setItem('language', 'en');
    document.getElementById('body')?.setAttribute('dir', this.language == 'ar' ? 'rtl' : 'ltr')
    this.translationService.use(this.language ?? 'en');
  }

  getRestaurantBySubdomain() {
      let body = {
        subdomain: 'bittersweet',
      };
      this.loading = true;
      this.authenticationService.getRestaurantBySubdomain(body).subscribe({
        next: (res:any) => {
          this.loading = false;          
          localStorage.setItem('restaurant_id', res.data.restaurants.id)
        },
        error: (err) => {
          this.loading = false
        },
      })
  }
}
