import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationServicesService } from '../services/auth-services/authentication-services.service';
@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
  constructor(
    private authService: AuthenticationServicesService,
    private router: Router
  ) {}
  canActivate(): boolean {
      this.authService.checkUserAuth();
      if(!this.authService.isUserAuth()) {
          // this.router.navigate(['/sign-in'])
          this.router.navigate(['/'])
        return false;
      } else {
        return true;
      }
  }
}